import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { UserContext } from '../../context/UserContext'
import { env } from '../../env'

const Footer = (props) => {
    const { userState, setUserState } = useContext(UserContext)
    const [openDropdown, setOpenDropdown] = useState(false)
    useEffect(() => {

    }, [])

    const handleDropdown = () => setOpenDropdown(!openDropdown)
    const handleFilter = (value) => {
        if (value === undefined) {
            axios.get(`${env}/api/user/banksoal`)
                .then(res => setUserState({ ...userState, data: res.data.data }))
                .catch(err => console.log(err))
        }
        else {
            axios.get(`${env}/api/user/banksoal/type/${value}`)
                .then(res => setUserState({ ...userState, data: res.data.data }))
                .catch(err => console.log(err))
        }
    }
    const handleSearch = (event) => {
        if (event.target.value === '') {
            axios.get(`${env}/api/user/banksoal`)
                .then(res => setUserState({ ...userState, data: res.data.data }))
                .catch(err => console.log(err))
        }
        else {
            axios.get(`${env}/api/user/banksoal/name/${event.target.value}`)
                .then(res => setUserState({ ...userState, data: res.data.data }))
                .catch(err => console.log(err))
        }
    }
    return (
        <>
            <div id="searchBox">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-11 col-md-8">
                            <div className="input-group">
                                <button className="btn bg-white outline-soft-pink text-blue fw-600 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleDropdown}>Filter </button>
                                <ul className={openDropdown ? 'dropdown-menu show' : 'dropdown-menu'} style={{ boxShadow: '0 0 0 0.25rem rgba(13, 110, 253, .25)', border: '3px solid #FFE6EE' }}>
                                    <li>
                                        <p className="dropdown-item text-blue fw-600" onClick={() => handleFilter()}>All</p>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>
                                    <li>
                                        <p className="dropdown-item text-blue fw-600" onClick={() => handleFilter('uas')}>UAS</p>
                                    </li>
                                    <li>
                                        <p className="dropdown-item text-blue fw-600" onClick={() => handleFilter('uts')}>UTS</p>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>
                                    <li>
                                        <p className="dropdown-item text-blue fw-600" onClick={() => handleFilter('materi')}>Materi</p>
                                    </li>
                                </ul>
                                <input type="text" className="form-control outline-soft-pink" onChange={handleSearch} style={{ borderLeft: 'none', borderRight: 'none' }} />
                                <button className="btn bg-white outline-soft-pink text-blue fw-600" type="button" id="button-addon1">Search</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Reset button and input on search box behavior */}
                <style dangerouslySetInnerHTML={{ __html: "\n            .btn-secondary:active:focus,\n            .btn-check:focus+.btn-secondary,\n            .btn-secondary:focus {\n                box-shadow: none;\n            }\n\n            .btn:hover {\n                color: #ffffff;\n            }\n\n            .form-control:focus {\n                border: 3px solid #FFE6EE;\n                box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);\n            }\n        " }} />
            </div>
        </>
    )
}

export default Footer