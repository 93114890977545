import { useState } from 'react'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm'

const ReportModal = (props) => {
    const closeModal = () => Modal.getInstance(document.getElementById('reportModal')).hide()
    const handleOnchange = (event) => {
        setState({ ...state, textArea: event.target.value, textArea2: event.target.value.replace(/(?:\r\n|\r|\n)/g, '%0a') })
    }
    const [state, setState] = useState({ textArea: '', textArea2: '' })
    const handleSubmit = () => window.open(`https://wa.me/6282154526025?text=${state.textArea2}`, '_blank')

    return (
        <>
            <div className="modal fade" id="reportModal" tabIndex={-1} aria-labelledby="reportModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header" style={{ border: 'none' }}>
                            <h3>Report</h3>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="reportInput" className="form-label fw-600 text-blue">Type your report here</label>
                                <textarea className="form-control outline-soft-pink" id="reportInput" rows={3} value={state.textArea} onChange={handleOnchange} />
                            </div>
                        </div>
                        <div className="modal-footer" style={{ border: 'none' }}>
                            <button type="button" className="btn bg-blue fw-600" onClick={closeModal} >Close</button>
                            <button id="reportBtn" type="button" className="btn bg-pink fw-600" onClick={handleSubmit} >Report</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportModal