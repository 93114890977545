
const Footer = (props) => {
    return (
        <>
            <footer className='bg-soft-' style={{ marginTop: 150, backgroundColor: '#122657' }}>
                <div className="container">
                    <div className="row justify-content-center p-5 pb-3">
                        <div className='col-12 col-lg-4'>
                            <h3 className='text-pink'>DEVELOPER</h3>
                            <ul className='p-0 mt-4'>
                                <li className='fw-500 text-blue'>Afif Luthfi Fadillah</li>
                                <li className='fw-500 text-blue'>Fahri Ramadhan</li>
                                <li className='fw-500 text-blue'>Gracella Andreni</li>
                                <li className='fw-500 text-blue'>Hutomo Sakti Kartiko</li>
                                <li className='fw-500 text-blue'>Madajabbar Palapa Hakim</li>
                                <li className='fw-500 text-blue'>Mella Geovanie</li>
                            </ul>
                        </div>
                        <div className='col-12 col-lg-4'>
                            <h3 className='text-pink'>CONTACT US</h3>
                            <ul className='p-0 mt-4'>
                                <li className='fw-500 text-blue'>himaster@fmipa.untan.ac.id</li>
                                <li className='fw-500 text-blue'>keilmuanhimaster@gmail.com</li>
                            </ul>
                        </div>
                        <div className='col-12 col-lg-4'>
                            <h3 className='text-pink'>ATTRIBUTES</h3>
                            <ul className='p-0 mt-4'>
                                <li className='fw-500 text-blue'>
                                    <a href="http://www.freepik.com">Designed by catalyststuff / Freepik</a>
                                </li>
                                <li className='fw-500 text-blue'>
                                    <a href="https://storyset.com/web">Illustration by Freepik Storyset</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer