export const StoreAuth = (name, value) => {
    const now = new Date()
    localStorage.setItem(name, JSON.stringify({
        data: value,
        expiry: now.getTime() + 43200*1000,
    }))
}

export const CheckAuth = (name, next, stop) => {
    const itemStr = localStorage.getItem(name)
    if (!itemStr) {
        return stop()
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(name)
        return stop()
    }
    return next()
}