import Header from '../../../components/Banksoal/Header'
import Search from '../../../components/Banksoal/Search'
import Content from '../../../components/Banksoal/Content'
import CardList from '../../../components/Banksoal/CardList'
import ReportModal from '../../../components/Banksoal/modal/ReportModal'
import PolicyModal from '../../../components/Banksoal/modal/PolicyModal'
import LoginModal from '../../../components/Banksoal/modal/LoginModal'
import Footer from '../../../components/Banksoal/Footer'
export const Banksoal = (props) => {
    return (
        <div className='user'>
            <Header />
            <Search />
            <Content>
                <CardList />
            </Content>
            <ReportModal />
            <PolicyModal />
            <LoginModal />
            <Footer />
        </div>
    )
}