import landingPage from '../../assets/images/digital-personal-files-animate.svg'

const Content = (props) => {
    return (
        <>
            {/* Content */}
            <div className="container">
                {/* Introduce */}
                <div className="row justify-content-center" style={{ marginTop: 100 }}>
                    <div className="col-11 col-md-6">
                        <div className="p-3">
                            <img src={landingPage} alt="Landing" />
                        </div>
                    </div>
                    <div className="col-11 col-md-6">
                        <div className="text-center align-items-center">
                            <br />
                            <br />
                            <br />
                            <h2 className='bounce'>Banksoal</h2>
                            <h3 className='bounce'>"Belajar, berusaha, dan berdo'a merupakan kunci terpenting"</h3>
                        </div>
                    </div>
                </div>
                {/* End Introduce */}
                {/* Content */}
                <div id="content" className="row gx-0 gx-md-5 gy-5 justify-content-center" style={{ marginTop: 100 }}>
                    <h3 className="text-center">List Item</h3>
                    {props.children}
                </div>
                {/* End Content */}
            </div>
            {/* End Container */}
        </>
    )
}

export default Content