import { Modal } from 'bootstrap/dist/js/bootstrap.esm'
import { useState } from 'react'
import cx from 'classnames'

const Header = (props) => {
    const [collapse, setcollapse] = useState(false)
    const handleCollpase = () => setcollapse(!collapse)
    const handleReportModal = () => new Modal(document.getElementById('reportModal'), { backdrop: 'static', keyboard: false }).show()
    const handlePolicyModal = () => new Modal(document.getElementById('policyModal'), { backdrop: 'static', keyboard: false }).show()
    const handleLoginModal = () => new Modal(document.getElementById('loginModal'), { backdrop: 'static', keyboard: false }).show()
    return (
        <>
            <nav className="navbar navbar-expand-md navbar-light bg-light d-flex container-fluid">
                <div className="col-12 col-md-5">
                    <div className="d-flex justify-content-between">
                        <h3>Divisi Keilmuan</h3>
                        <button className="navbar-toggler outline-soft-pink" type="button" onClick={handleCollpase} aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <style dangerouslySetInnerHTML={{ __html: "\n.navbar-toggler:focus {\nbox-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);\n}\n" }} />
                    </div>
                </div>
                <div className="col-12 col-md-7">
                    <div className={cx('customCollapse navbar-collapse justify-content-end fw-600 ', { 'showCustomCollapse': collapse })} >
                        <p className="nav-link" style={{ color: '#999', cursor: 'pointer' }} onClick={handleReportModal} >Report</p>
                        <p className="nav-link" style={{ color: '#999', cursor: 'pointer' }} onClick={handlePolicyModal} >Policy</p>
                        <p className="nav-link" style={{ color: '#999', cursor: 'pointer' }} onClick={handleLoginModal} >Sign in</p>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header