import { useEffect, useContext } from 'react'
import axios from 'axios'
import { UserContext } from '../../context/UserContext'
import card1 from '../../assets/images/card-img-1.jpg'
import card2 from '../../assets/images/card-img-2.jpg'
import card3 from '../../assets/images/card-img-3.jpg'
import card4 from '../../assets/images/card-img-4.jpg'
import card5 from '../../assets/images/card-img-5.jpg'
import card6 from '../../assets/images/card-img-6.jpg'
import card7 from '../../assets/images/card-img-7.jpg'
import card8 from '../../assets/images/card-img-8.jpg'
import card9 from '../../assets/images/card-img-9.jpg'
import card10 from '../../assets/images/card-img-10.jpg'
import { CheckAuth } from '../../middleware/Auth'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm'
import { env } from '../../env'
import 'aos/dist/aos.css'
import AOS from 'aos'


const CardList = (props) => {
    AOS.init();
    const { userState, setUserState } = useContext(UserContext)
    const cardImage = [card1, card2, card3, card4, card5, card6, card7, card8, card9, card10]
    useEffect(() => {
        if (userState.data === null) {
            axios.get(`${env}/api/user/banksoal`)
                .then((res) => setUserState({ ...userState, data: res.data.data }))
                .catch((err) => console.log(err))
        }
    }, [userState, setUserState])

    const handleLoginModal = () => new Modal(document.getElementById('loginModal'), { backdrop: 'static', keyboard: false }).show()

    const handleDownload = (id) => {
        CheckAuth('auth', () => {
            const key = JSON.parse(localStorage.getItem('auth'))
            const config = {
                method: 'post',
                url: `${env}/api/user/banksoal/download/${id}`,
                headers: {
                    'login': key.data,
                },
            };
            axios(config)
                .then(res => window.open(res.data.data, '_blank'))
                .catch(err => localStorage.removeItem('auth'))
        }, () => {
            handleLoginModal()
        })
    }

    return (
        <>
            {userState.data !== null ? [(userState.data.length !== 0 ? (
                (
                    userState.data.map((value, index) => {
                        return (
                            <div className="col-11 col-md-4" key={index}
                            >
                                <div className="card-wrapper rounded" data-aos="flip-right"
                                    data-aos-anchor-placement="top-center"
                                    data-aos-duration={1000} onClick={() => handleDownload(value.id)} >
                                    <img className="card-img" src={cardImage[Math.floor(Math.random() * cardImage.length)]} alt='card' />
                                    <div className="p-3">
                                        <p className="fw-600">{value.name}  ({value.type})</p>
                                        <br />
                                        <p style={{ fontSize: '0.9rem' }}>*click to download</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                )
            ) : (<p key='notFoundSearch' className="fw-600 text-pink text-center">Data not found</p>))] :
                (<p key='notFoundinTable' className="fw-600 text-pink text-center">Loading...</p>)
            }
        </>
    )
}

export default CardList