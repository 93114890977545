import { Modal } from 'bootstrap/dist/js/bootstrap.esm'


const PolicyModal = (props) => {
    const closeModal = () => Modal.getInstance(document.getElementById('policyModal')).hide()
    return (
        <>
            <div className="modal fade" id="policyModal" tabIndex={-1} aria-labelledby="policyModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header" style={{ border: 'none' }}>
                            <h3 className="modal-title" id="policyModalLabel">Policy</h3>
                        </div>
                        <div className="modal-body">
                            <ol>
                                <li className="fw-600 text-blue">Not for sale</li>
                                <li className="fw-600 text-blue">Not for public</li>
                                <li className="fw-600 text-pink">Just to study</li>
                            </ol>
                        </div>
                        <div className="modal-footer" style={{ border: 'none' }}>
                            <button type="button" className="btn bg-pink fw-600" onClick={closeModal} >I understood</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PolicyModal