import { Modal } from 'bootstrap/dist/js/bootstrap.esm'
import { Spinner } from 'react-bootstrap'
import { useState } from 'react'
import axios from 'axios'
import { StoreAuth } from '../../../middleware/Auth'
import { env } from '../../../env'
const LoginModal = (props) => {
    const [key, setKey] = useState('')
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('*')
    const closeModal = () => Modal.getInstance(document.getElementById('loginModal')).hide()
    const handleKeyChanged = (element) => setKey(element.target.value)
    const handleErrorMessage = (message) => {
        setErrorMessage(message)
        setTimeout(() => {
            if (message.toLowerCase().includes('success')) closeModal()
            setErrorMessage('*')
        }, 5000)
    }
    const handleLogin = () => {
        setLoading(true)
        axios.post(`${env}/api/user/login`, { username: 'fahri', password: key })
            .then((res) => {
                handleErrorMessage(res.data.message)
                if (res.data.message.toLowerCase().includes('success')) {
                    StoreAuth('auth', res.data.data)
                }
                setLoading(false)
            })
            .catch((err) => console.log(err))
    }
    return (
        <>
            <div className="modal fade" id="loginModal" tabIndex={-1} aria-labelledby="loginModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header" style={{ border: 'none' }}>
                            <h3 id="loginModalLabel">Authentication</h3>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="keyInput" className="form-label fw-600 text-blue">Your Access Key</label>
                                <input id="keyInput" className="form-control outline-soft-pink" type="text" autoComplete="off" value={key} onChange={handleKeyChanged} />
                                <p className="fw-600 text-pink mt-2">{errorMessage}</p>
                            </div>
                        </div>
                        <div className="modal-footer" style={{ border: 'none' }}>
                            <button type="button" className="btn bg-blue fw-600" onClick={closeModal}>Close</button>
                            <button type="button" className="btn bg-pink fw-600" onClick={handleLogin}>
                                {loading && <Spinner style={{ marginRight: '8px' }} as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                                Sign in
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginModal